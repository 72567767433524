import VoiceItem from './VoiceItem'
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Select, Space, Switch, Tooltip } from 'antd'
import { MenuProps } from 'antd/lib'
import { voices } from '../../../utils/voices'
import { apiGetListWithParams } from '../../../api/crud'
interface VoiceListProps {
  onVoiceChange: (voice: string | number | null) => void
  lang: string
}

const VoiceList: React.FC<VoiceListProps> = ({ onVoiceChange, lang }) => {
  const [selectedVoice, setSelectedVoice] = useState<string | null>(null)
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)
  const [filteredVoices, setFilteredVoices] = useState<Api.Response.Voice[]>([])
  const [chooseSex, setChooseSex] = useState<'f' | 'm'>('m')
  const getVoices = async () => {
    const res = await apiGetListWithParams<Api.Response.Voice[]>('translate/get_voices', {
      offset: 0,
      limit: 100,
      language: lang,
      gender: chooseSex
    })
    if (res.isError) {
      console.error(res.data)
      return
    } else {
      return res.data
    }
  }
  const handleVoiceChange = (value: string | number | null, name: string) => {
    if (value) {
      setSelectedVoice(name)
      onVoiceChange(value)
    }

    if (audio) {
      audio.pause()
    }
  }

  const playSample = (sample: string) => {
    if (audio) {
      audio.pause()
    }
    const audioInstance = new Audio(sample)
    setAudio(audioInstance)
    audioInstance.play()
  }

  const handlePlayClick = (sample: string, e: React.MouseEvent) => {
    e.stopPropagation()
    playSample(sample)
  }
  useEffect(() => {
    if (lang) {
      getVoices().then(data => {
        if (data && data.length > 0) {
          setFilteredVoices(data)
          // Устанавливаем первый голос из списка только после получения новых голосов
          if (data[0].id && data[0].voice_name) {
            setSelectedVoice(data[0].voice_name)
            onVoiceChange(data[0].id)
          }
        }
      })
    }
  }, [lang, chooseSex])

  const items: MenuProps['items'] = filteredVoices?.map((voice, iterator) => ({
    key: iterator,
    label: (
      <VoiceItem
        name={voice.voice_name}
        sample={voice?.sample_url || ''}
        value={voice.id}
        handlePlayClick={handlePlayClick}
        handleVoiceChange={handleVoiceChange}
      />
    )
  }))

  return (
    <>
      <Select
        options={[
          { value: 'f', label: 'Женский' },
          { value: 'm', label: 'Мужской' }
        ]}
        value={chooseSex}
        onChange={value => setChooseSex(value as 'f' | 'm')}
        style={{ width: '100%', maxWidth: '320px', marginBottom: '10px' }}
      />

      <Dropdown menu={{ items }} trigger={['click']} overlayStyle={{ maxWidth: '320px' }}>
        <Button style={{ width: '100%', maxWidth: '320px' }}>{selectedVoice || 'Выберите голос'}</Button>
      </Dropdown>
    </>
  )
}

export default VoiceList
