//@ts-nocheck
//TODO подгружать тариф с апи для простановки в состояние для сброса опций при выборе вотермарки
import React from 'react'
import { CoreForm } from '../../components/CoreForm'
import { Card, Col, Flex, Form, Row, Spin, Tag, Tooltip, Typography, theme } from 'antd'
import { message } from 'antd'
import { apiCreate, apiGetOne } from '../../api/crud'
import VideoSteps from './components/Steps'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks/useAppSelector'
import VideoSettingsForm from './components/VideoSettingForm'
import Editor from './components/Editor'
import EditorWrapper from './components/EditorWrapper'
// import './components/bootstrap.min.css'
import ErrorVideo from './components/ErrorVideo'
import SuccessVideo from './components/SuccessVideo'
import ProcessingVideo from './components/ProcessingVideo'
import { DynamicStyleLoader } from '../../components/DynamicStyleLoader'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { setVideoInfo } from '../../store/slices/video'

const VideoSetting = () => {
  const pageParams = useParams()
  let [searchParams] = useSearchParams()
  const restartVideo = searchParams.get('restart')
  const id = pageParams.id
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const user: Api.Response.User = useAppSelector((state: any) => state.user.data)
  const [current, setCurrent] = React.useState(1)
  const [videoSettings, setVideoSettings] = React.useState({
    language: 'en',
    save_origin_voice: false,
    has_logo: true,
    notification: false,
    voice_clone: false,
    lipsync: false,
    voice_id: null,
    voice_count: 1,
    subtitle_download: true, //Убрали, вкл для всех.
    subtitle_on_video: false, //Премиум
    subtitle_edit: false
  })
  const [loading, setLoading] = React.useState(false)
  const [info, setInfo] = React.useState<Api.Response.VideoTranslate | null>() //Данные о видео
  const [price, setPrice] = React.useState<Api.Response.VideoPrice | null>() //Ответ от ручки с тарифами
  const [translateResult, setTranslateResult] = React.useState([]) //Ответ от ручки запуска перевода
  const [status, setStatus] = React.useState(null) //Текущий статус перевода
  const [disabledLogo, setDisabledLogo] = React.useState(false)
  const [error, setError] = React.useState({
    notEnoughMoney: false
  })
  const [getInfoError, setGetInfoError] = React.useState()
  const { useToken } = theme
  const { token } = useToken()
  const dispatch = useAppDispatch()

  const getPrice = async () => {
    try {
      if (!videoSettings.voice_id) {
        setPrice(null)
        return
      }

      const req = await apiCreate<Api.Response.VideoPrice>(`translate/${id}/price`, videoSettings)

      if (req.isError) {
        throw new Error(req.data.detail)
      }
      if (req.data.plan.name === 'Демо' && !videoSettings.has_logo) {
        form.setFieldValue('has_logo', true)
        setVideoSettings(prev => ({ ...prev, has_logo: true }))
      }
      setPrice(prevPrice => {
        if (prevPrice?.price !== req.data.price) {
          messageApi.success(`Обновили цену.`)
        }
        return req.data
      })
      if (parseInt(req.data.price) > parseInt(user.balance)) {
        setError({ notEnoughMoney: true })
        throw new Error('Недостаточно средств')
      }

      setError({ notEnoughMoney: false })
    } catch (err) {
      if (err.message === 'Plan not found' && !disabledLogo) {
        form.setFieldValue('has_logo', false)
        setVideoSettings(prev => ({ ...prev, has_logo: false }))
        setDisabledLogo(true)
      } else {
        console.error(err)
        messageApi.error('Ошибка расчета цены, попробуйте сменить настройки =(')
      }
    }
  }

  const getInfo = async () => {
    setLoading(true)
    try {
      const req = await apiGetOne<Api.Response.VideoTranslate>(`translate`, id)
      if (req.isError) {
        throw new Error(req.data.detail)
      }
      setInfo(req.data)
      dispatch(
        setVideoInfo({
          info: req.data
        })
      )
    } catch (err) {
      setGetInfoError(true)
      console.error(err)
    }
    setLoading(false)
  }
  const getStatus = async () => {
    try {
      const req = await apiGetOne(`translate/${id}/status/`)
      if (req.isError) {
        setStatus('Ошибка')
        throw new Error(req.data.detail)
      }
      if (req) {
        setStatus(req.data.status)
        if (req.data.status === 'Ожидание пользователя') {
          setCurrent(3)
          // getInfo()
        }

        if (req.data.status === 'Ошибка') {
          messageApi.error('Произошла ошибка')
          getInfo()
          // setCurrent(5)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const startTranslate = async () => {
    try {
      const req = await apiCreate(`translate/${id}/setting`, videoSettings)
      if (req.isError) {
        throw new Error(req.data.detail)
      }
      messageApi.success(`Начали перевод.`)
      setTranslateResult(req)
      setCurrent(2)
      getStatus()
    } catch (err) {
      console.error(err)
    }
  }
  const changeStatus = (status: number) => {
    setCurrent(status)
  }
  //Запрашиваем инфу о файле
  React.useEffect(() => {
    getInfo()
    getStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const isValidSettings = (settings: typeof videoSettings) => {
    return (
      settings.language &&
      (settings.voice_clone || settings.voice_id) && // либо клонирование голоса, либо выбран голос
      typeof settings.has_logo !== 'undefined'
    )
  }
  //Узнаем цену при обновлении опций
  React.useEffect(() => {
    if (isValidSettings(videoSettings)) {
      const timer = setTimeout(() => {
        getPrice()
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [videoSettings, id])

  ////////////////////////////////////////////////////////Управление статусами///////////////////////////////////////////////////////
  React.useEffect(() => {
    if ((status === 'Неизвестно' && info?.current_task === null) || status === 'Видео загружено' || !!restartVideo) {
      setCurrent(1) //Видео ждет выбора параметров
      return
    }
    if (status === 'Обработка субтитров' || status === 'Обработка видео' || status === 'Формирование голоса') {
      setCurrent(2) //Выводится лоадер
      return
    }
    if (status === 'Ожидание пользователя') {
      setCurrent(3) //Редактирование субтитров
      return
    }
    if (status === 'Завершено' || status === 'Ошибка') {
      getInfo()
      setCurrent(5) //Скачивание видео
      return
    }
    if (status === 'Неизвестно' && info?.current_task !== null && info?.current_task !== undefined) {
      setCurrent(5) //Статус слетел
      return
    }
  }, [status, info?.current_task])

  //Узнаем статус обработки
  React.useEffect(() => {
    let timer
    if (current === 2 || status === null) {
      timer = setInterval(getStatus, 5000)
    }
    current === 5 && clearInterval(timer)
    return () => clearInterval(timer)
  }, [current, status])

  /** STEP 2 FORM SETTING START */
  const [form] = Form.useForm()
  const handleSave = async (values: any) => {
    setVideoSettings(prevState => ({
      ...prevState,
      ...values
    }))
    if ('has_logo' in values) {
      // if (values.has_logo) {
      //   setVideoSettings(prevState => ({
      //     ...prevState,
      //     language: 'en',
      //     save_origin_voice: false,
      //     has_logo: true,
      //     notification: false,
      //     voice_clone: false,
      //     lipsync: false,
      //     subtitle_download: true,
      //     subtitle_on_video: false,
      //     subtitle_edit: true
      //   }))
      //   form.resetFields()
      // }
    }
  }

  const priceBlock = () => {
    if (current !== 1) return
    return (
      <Typography.Paragraph style={{ margin: 0 }}>
        <Typography.Text type='secondary'>Ваша цена ({info?.video?.name}): </Typography.Text>
        <b>{price?.price}₽</b>
        <Tooltip title={`${price?.plan?.price_per_minute}₽/минута`} color={token.colorPrimary}>
          <Tag bordered={false} color='processing' style={{ marginLeft: 10 }}>
            {price?.plan?.name}
          </Tag>
        </Tooltip>
      </Typography.Paragraph>
    )
  }
  /** STEP 2 FORM SETTING END */
  if (getInfoError)
    return (
      <CoreForm title='Ошибка'>
        <div>
          <Typography.Text type='danger'>Видео не найдено</Typography.Text>
        </div>
      </CoreForm>
    )
  if (loading || status === null)
    return (
      <CoreForm title='Добавить видео'>
        <div>
          <Typography.Text type='secondary'>Загрузка...</Typography.Text>
        </div>
      </CoreForm>
    )
  return (
    <>
      <DynamicStyleLoader url={'/assets/bootstrap.min.css'} />
      {contextHolder}
      <CoreForm title='Добавить видео' extra={priceBlock()}>
        <VideoSteps current={current} />
        {current === 1 && (
          <VideoSettingsForm
            form={form}
            videoSettings={videoSettings}
            handleSave={handleSave}
            price={price}
            user={user}
            error={error}
            startTranslate={startTranslate}
            info={info}
            disabledLogo={disabledLogo}
          />
        )}
        {current === 2 && (
          <>
            <Row>
              <Col span={24}>
                <ProcessingVideo info={info} status={status} />
              </Col>
            </Row>
          </>
        )}
        {current === 3 && (
          <Row>
            <Col span={24}>
              <EditorWrapper id={id} setCurrent={setCurrent} />
            </Col>
          </Row>
        )}
        {current === 5 && (
          <Row>
            <Col span={24}>
              {status === 'Ошибка' || status === 'Неизвестно' ? (
                <ErrorVideo changeStatus={changeStatus} />
              ) : (
                <SuccessVideo info={info} />
              )}
            </Col>
          </Row>
        )}
      </CoreForm>
    </>
  )
}
export default VideoSetting
