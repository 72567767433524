import { Button, Col, Row } from 'antd'
import play from '../../../assets/play.png'
const VoiceItem = ({
  name,
  sample,
  value,
  handlePlayClick,
  handleVoiceChange
}: {
  name: string
  sample: string
  value: string | number
  handlePlayClick: (sample: string, e: React.MouseEvent) => void
  handleVoiceChange: (value: string | number, name: string) => void
}) => {
  return (
    <>
      <Row align='middle'>
        <Col span={6}>
          <Button
            onClick={e => handlePlayClick(sample, e)}
            style={{ height: '24px', width: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src={play} alt='' width='16px' />
          </Button>
        </Col>
        <Col span={18} onClick={() => handleVoiceChange(value, name)}>
          {name}
        </Col>
      </Row>
    </>
  )
}

export default VoiceItem
