import React, { useState, useEffect } from 'react'
import { Form, message } from 'antd'
import { apiGetList, apiGetOne, apiUpdate } from '../../api/crud'
import { CoreForm } from '../../components/CoreForm'
import { useParams } from 'react-router-dom'
import PlanForm from './PlanForm'
import { useLanguages } from '../../hooks/useLanguage'

const PlanEdit = () => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const pageParams = useParams()
  const id = pageParams.id
  const [loading, setLoading] = useState(true)
  const [form] = Form.useForm()
  const [data, setData] = useState<Api.Response.Plan>()
  const { languageOptions } = useLanguages()

  const getData = async () => {
    if (id) {
      const result = await apiGetOne('plan', Number.parseInt(id))
      if (result.isError) {
        setLoading(true)
        return
      }
      setLoading(false)
      return result.data
    }
  }

  useEffect(() => {
    if (loading) {
      getData().then(data => {
        if (data) {
          const formattedData = {
            ...data,
            languages: data.languages ? data.languages.split(',') : []
          }
          setData(data)
          form.setFieldsValue(formattedData)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async (values: Api.Response.Plan) => {
    if (id) {
      const formattedValues = {
        ...values,
        languages: Array.isArray(values.languages) ? values.languages.join(',') : values.languages
      }
      const result = await apiUpdate('plan', parseInt(id), formattedValues)
      if (result.isError) {
        messageApi.error('Произошла ошибка при изменении тарифа')
      } else {
        messageApi.success('Изменения успешно сохранены')
      }
    }
  }
  return (
    <CoreForm title='Тарифы'>
      {contextHolder}
      <PlanForm startValue={data} handleSave={handleSave} form={form} languageOptions={languageOptions} />
    </CoreForm>
  )
}

export default PlanEdit
