import { Form, Button, Select, Image } from 'antd'
import { FormatFormInput } from '../../components/FormatFormInput'
import type { FormInstance } from 'antd/lib'
import { languageOptions as LanguageList } from '../../utils/voices_list'

const PlanForm = ({
  form,
  startValue,
  handleSave,
  languageOptions
}: {
  form: FormInstance
  startValue: Api.Response.Plan | undefined
  handleSave: (values: Api.Response.Plan) => void
  languageOptions: { value: string; label: React.ReactNode; icon: string }[]
}) => {
  console.log([startValue?.languages.split(',')])
  console.log(languageOptions)
  return (
    <Form form={form} initialValues={startValue} labelCol={{ span: 4 }} onFinish={handleSave}>
      <FormatFormInput label='Наименование' name='name' />
      <Form.Item label='Языки' name='languages'>
        <Select
          mode='multiple'
          style={{ maxWidth: '320px' }}
          options={languageOptions.map(option => ({
            value: option.value,
            label: (
              <>
                <Image src={option.icon} className='icons_flag' preview={false} width={20} />{' '}
                {LanguageList.find(item => item.value === option.value)?.label}
              </>
            )
          }))}
        />
      </Form.Item>
      <FormatFormInput label='Макс. длина видео, минут' name='max_length_video' typeData='number' />
      <FormatFormInput label='Логотип' name='has_logo' typeData='boolean' />
      <FormatFormInput label='Сохранить голос' name='save_origin_voice' typeData='boolean' />
      <FormatFormInput label='Уведомление' name='notification' typeData='boolean' />
      <FormatFormInput label='Клонирование голоса' name='voice_clone' typeData='boolean' />
      <FormatFormInput label='Липсинк' name='lipsync' typeData='boolean' />
      <FormatFormInput label='Из ютуба' name='from_youtube' typeData='boolean' />
      <FormatFormInput label='Скачивание субтитров' name='subtitle_download' typeData='boolean' />
      <FormatFormInput label='Наложение субтитров' name='subtitle_on_video' typeData='boolean' />
      <FormatFormInput label='Редактирование субтитров' name='subtitle_edit' typeData='boolean' />
      <FormatFormInput label='Приоритет' name='queue' typeData='number' />
      <FormatFormInput label='Цена' name='price_per_minute' />
      <FormatFormInput label='Тариф активен' name='is_active' typeData='boolean' />
      <Button onClick={form.submit} type='primary'>
        Сохранить
      </Button>
    </Form>
  )
}

export default PlanForm
