import { useState, useEffect } from 'react'
import { apiGetList } from '../api/crud'

export const useLanguages = () => {
  const [language, setLanguage] = useState<Api.Response.Language[]>([])
  const [languageOptions, setLanguageOptions] = useState<{ value: string; label: React.ReactNode; icon: string }[]>([])

  const getLanguages = async () => {
    const res = await apiGetList<Api.Response.Language[]>('translate/get_languages', { offset: 0, limit: 100 })
    if (res.isError) {
      console.error(res.data)
      return
    }
    return res.data
  }

  useEffect(() => {
    getLanguages().then(data => data && setLanguage(data))
  }, [])

  useEffect(() => {
    setLanguageOptions(
      language.map(item => ({
        value: item.language,
        label: item.language,
        icon: item.icon_url
      }))
    )
  }, [language])

  return { languageOptions }
}
